/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
#ForRadioOptions .alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

#ForCheckbox .alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-checkbox {
  height: auto;
  contain: content;
}

ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}

ion-alert.select-alert {
  --width: 35vw !important;
  --max-width: 66vw !important;
}
ion-alert.select-alert .select-interface-option .alert-radio-label,
ion-alert.select-alert .select-interface-option .alert-checkbox-label {
  white-space: normal !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.195);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.alert-head.sc-ion-alert-md + .alert-message.sc-ion-alert-md {
  user-select: text !important;
  margin: 0px 10px;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  color: #3842ae;
}

.segment-button-checked {
  --color-checked: #8cb5ff;
  border-right: 2px solid #8cb5ff !important;
  border-top: 2px solid #8cb5ff !important;
  border-left: 2px solid #8cb5ff !important;
  font-weight: bold;
  margin-bottom: 3px;
}

.alert-wrapper.sc-ion-alert-md {
  width: 100% !important;
  max-width: 60% !important;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
  font-size: 16px;
  color: #6b6b6b;
}